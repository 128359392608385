// 买家
export const wordsList = [
  { value: '这款商品有货吗？' },
  { value: '能否提供商品的详细尺寸/规格？' },
  { value: '商品的价格能再优惠一些吗？' },
  { value: '有没有满减活动或优惠券可以使用？' },
  { value: '商品是否包邮？' },
  { value: '我的订单什么时候发货？' },
  { value: '如果商品有问题，可以退货或换货吗？' },
  { value: '我需要修改订单信息（如地址、数量等），怎么办？' },
];

// 商家
export const wordsServiceList = [
  { value: '亲，欢迎光临普夷堂！有什么可以帮助您的吗？' },
  { value: '亲亲，非常抱歉让您久等了。目前咨询人数较多，我会尽快回复您，请您稍等片刻。' },
  { value: '您好，我们的商品价格已经是最优惠的了，感谢您的理解和支持' },
  { value: '亲，目前我们正在进行促销活动，优惠多多，欢迎您参加哦！' },
  { value: '亲，您的订单已经成功提交，我们会尽快为您安排发货，请您放心等待' },
  { value: '亲，如果您收到商品后有任何问题或不满意，都可以联系我们进行售后处理。' },
  { value: '感谢您的咨询，祝您生活愉快，期待您再次光临！' },
  { value: '祝您购物愉快，如果有任何问题，随时欢迎您再次联系我们。' },
];
