var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Overlay,{attrs:{"maskColor":'transparent'},on:{"onOverlayClick":() => _setup.emits('closeConversationActionMenu')}},[_c('div',{ref:"actionsMenuDomRef",class:[
        _setup.isPC && 'actions-menu-pc',
        'actions-menu',
        !_setup.isHiddenActionsMenu && 'cancel-hidden',
      ],style:({
        top: `${_setup._actionsMenuPosition.top}px`,
        left: `${_setup._actionsMenuPosition.left}px`,
      }),attrs:{"id":"conversation-actions-menu"}},[(!(_setup.props.selectedConversation && _setup.props.selectedConversation.isPinned))?_c('div',{class:['actions-menu-item'],on:{"click":function($event){$event.stopPropagation();return _setup.handleItem({ name: _setup.CONV_OPERATION.ISPINNED })}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIConversation.置顶会话"))+" ")]):_vm._e(),(_setup.props.selectedConversation && _setup.props.selectedConversation.isPinned)?_c('div',{class:['actions-menu-item'],on:{"click":function($event){$event.stopPropagation();return _setup.handleItem({ name: _setup.CONV_OPERATION.DISPINNED })}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIConversation.取消置顶"))+" ")]):_vm._e(),(!(_setup.props.selectedConversation && _setup.props.selectedConversation.isMuted))?_c('div',{class:['actions-menu-item'],on:{"click":function($event){$event.stopPropagation();return _setup.handleItem({ name: _setup.CONV_OPERATION.MUTE })}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIConversation.消息免打扰"))+" ")]):_vm._e(),(_setup.props.selectedConversation && _setup.props.selectedConversation.isMuted)?_c('div',{class:['actions-menu-item'],on:{"click":function($event){$event.stopPropagation();return _setup.handleItem({ name: _setup.CONV_OPERATION.NOTMUTE })}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIConversation.取消免打扰"))+" ")]):_vm._e()]),_c(_setup.Dialog,{attrs:{"show":_setup.isShowDeleteConversationDialog,"center":true,"isHeaderShow":_setup.isPC},on:{"submit":function($event){return _setup.handleItem({ name: _setup.CONV_OPERATION.DELETE })},"update:show":_setup.updateShowDeleteConversationDialog}},[_c('p',{staticClass:"delDialog-title"},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t(_setup.deleteConversationDialogTitle))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }