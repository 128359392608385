const Words = {
  "常用语-快捷回复工具": "常用语-快捷回复工具",
  "在吗？在吗？在吗？重要的话说三遍。": "在吗？在吗？在吗？重要的话说三遍。2333333",
  "好久没聊天了，快来和我说说话～": "好久没聊天了，快来和我说说话～",
  "好的，就这么说定了。": "好的，就这么说定了。",
  "感恩的心，感谢有你。": "感恩的心，感谢有你。",
  "糟糕！是心动的感觉！": "糟糕！是心动的感觉！",
  "心疼地抱抱自己，我太难了！":  "心疼地抱抱自己，我太难了！",
  "没关系，别在意，事情过去就过去了。": "没关系，别在意，事情过去就过去了。",
  "早上好，今天也是让人期待的一天呢！": "早上好，今天也是让人期待的一天呢！",
  "熬夜有什么用，又没人陪你聊天，早点休息吧。": "熬夜有什么用，又没人陪你聊天，早点休息吧。",
  "熬夜": "22222222"
}

export default Words;
