var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
      _vm.searchType === 'global' ||
        ((_vm.searchType === 'conversation' || (!_vm.searchType && _setup.isUniFrameWork)) &&
          _setup.isShowInConversationSearch)
    )?_c('div',{class:[
      'tui-search',
      !_setup.isPC && 'tui-search-h5',
      `tui-search-main-${_vm.searchType ? _vm.searchType : 'conversation'}`,
      _setup.isFullScreen && 'tui-search-h5-full-screen',
    ]},[(_vm.searchType === 'global')?_c('div',{ref:"globalSearchRef",class:['tui-search-global', !_setup.isPC && 'tui-search-h5-global']},[_c('div',{class:[
          'tui-search-global-header',
          !_setup.isPC && 'tui-search-h5-global-header',
        ]},[_c(_setup.SearchInput,{staticClass:"search-input",attrs:{"searchType":_vm.searchType}})],1),(_setup.searchingStatus)?_c(_setup.SearchContainer,{staticClass:"search-container",attrs:{"popupPosition":"bottom","searchType":_vm.searchType},scopedSlots:_vm._u([{key:"result",fn:function(){return [_c(_setup.SearchResult,{staticClass:"search-result",attrs:{"searchType":_vm.searchType}})]},proxy:true}],null,false,2406171712)}):_vm._e()],1):(
        (_vm.searchType === 'conversation' && _setup.isShowInConversationSearch) ||
          _setup.isUniFrameWork
      )?_c('div',{class:[
        'tui-search-conversation',
        !_setup.isPC && 'tui-search-h5-conversation',
      ]},[_c(_setup.SearchContainer,{staticClass:"search-container",attrs:{"popupPosition":"aside","searchType":_vm.searchType ? _vm.searchType : 'conversation'},on:{"closeInConversationSearch":_setup.closeInConversationSearch},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c(_setup.SearchInput,{attrs:{"searchType":_vm.searchType ? _vm.searchType : 'conversation'}})]},proxy:true},{key:"result",fn:function(){return [_c(_setup.SearchResult,{staticClass:"search-result",attrs:{"searchType":_vm.searchType ? _vm.searchType : 'conversation'}})]},proxy:true}],null,false,2008817795)})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }