var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['tui-contact-search', !_setup.isPC && 'tui-contact-search-h5']},[(!_setup.isSearching || !_setup.isPC)?_c('div',{class:[
      'tui-contact-search-header',
      !_setup.isPC && 'tui-contact-search-h5-header',
      _setup.isSearching && 'tui-contact-searching-h5-header',
    ],on:{"click":_setup.handleSearchingHeaderClicked}},[_c('div',{class:[
        'tui-contact-search-header-icon',
        !_setup.isPC && 'tui-contact-search-h5-header-icon',
      ],on:{"click":function($event){$event.stopPropagation();return _setup.handleSearchingIconClicked.apply(null, arguments)}}},[_c(_setup.Icon,{attrs:{"file":_setup.isSearching ? _setup.backSVG : _setup.addSVG,"width":_setup.isSearching ? '20px' : '14px',"height":_setup.isSearching ? '20px' : '14px'}})],1),_c('div',{class:[
        'tui-contact-search-header-title',
        !_setup.isPC && 'tui-contact-search-h5-header-title',
      ]},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIContact.添加好友/群聊"))+" ")])]):_vm._e(),(_setup.isSearching)?_c('div',{class:[
      'tui-contact-search-main',
      !_setup.isPC && 'tui-contact-search-h5-main',
    ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.searchValue),expression:"searchValue"}],staticClass:"tui-contact-search-main-input",attrs:{"type":"text","placeholder":_setup.searchingPlaceholder,"enterkeyhint":"search"},domProps:{"value":(_setup.searchValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.search.apply(null, arguments)},"blur":_setup.search,"confirm":_setup.search,"input":function($event){if($event.target.composing)return;_setup.searchValue=$event.target.value}}}),_c('div',{staticClass:"tui-contact-search-main-cancel",on:{"click":function($event){_setup.isSearching = false}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("取消"))+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }